<template>
  <div class="editable-list">
    <sm-page-loader v-if="isLoadingPage" />

    <sm-breadcrumbs :items="breadcrumbs" class="editable-list__breadcrumbs" />

    <sm-datatable
      :caption="tableCaption"
      :headers="tableHeaders"
      :items="items"
    >
      <template #top-panel>
        <button
          v-if="showEditButton"
          class="editable-list__top-panel-button editable-list__top-panel-button--create"
          @click="onAdd"
        >
          <sm-icon name="plus" />
          Добавить
        </button>
      </template>

      <template #actions="{ index }">
        <button v-if="showEditButton" class="editable-list__datatable-button" @click="onEdit(index)">
          <sm-icon name="pencil-alt" />
        </button>

        <button v-if="showEditButton" class="editable-list__datatable-button" @click="onDelete(index)">
          <sm-icon name="trash" />
        </button>
      </template>
    </sm-datatable>

    <sm-button
      colorType="primary"
      @click="onSave"
      class="filter__button"
      :disabled="isEqual"
    >
      Сохранить
    </sm-button>

    <sm-button neutrall outline @click="onCancel" class="filter__button">
      Отменить
    </sm-button>

    <sm-form-modal
      v-model="modalForm"
      :fields="modalFields"
      :show="showModal"
      :modal-title="modalTitle"
      :disabledSaveButton="!modalForm.factoryNumber || !modalForm.resourceId || !modalForm.verificationDate || !modalForm.nextVerificationDate"
      @close="onCancelModal"
      @save="onConfirmModal"
      @cancel="onCancelModal"
      class="meter-recheck-requests__modal"
    />
  </div>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';

// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';

export default {
  name: 'MeterRecheckRequestsEdit',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmButton,
    SmDatatable,
    SmIcon,
    SmFormModal,
  },

  data() {
    return {
      isLoadingPage: false,
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Заявки на поверку ИПУ',
          route: { name: 'MeterRecheckRequests' },
        },
        {
          text: 'Список ИПУ по заявке',
        },
      ],
      tableCaption: 'Список ИПУ по заявке',
      items: [],
      initialItems: [],
      editItemIndex: null,
      showModal: false,
      modalTitle: '',
      modalForm: {
        factoryNumber: '',
        resourceId: 0,
        resourceName: '',
        recheckInterval: 0,
        value: 0,
        verificationDate: '',
        nextVerificationDate: '',
      },
    };
  },

  computed: {
    ...mapState({
      resources: (state) => state.meterRecheckRequests.resources,
    }),

    isEqual() {
      return this.lodash.isEqual(this.items, this.initialItems);
    },

    showEditButton() {
      return this.$ability.checkPermissions(
        'Table',
        'MeterRecheckRequests',
        'Edit'
      );
    },

    tableHeaders() {
      const baseFields = [
        {
          text: 'Заводской номер',
          order: 'factoryNumber',
          alias: 'factoryNumber',
        },
        {
          text: 'Измеряемый ресурс',
          order: 'resourceName',
          alias: 'resourceName',
        },
        {
          text: 'Межповерочный интервал',
          order: 'recheckInterval',
          alias: 'recheckInterval',
        },
        {
          text: 'Показание при поверке',
          order: 'value',
          alias: 'value',
        },
        {
          text: 'Дата поверки',
          order: 'verificationDate',
          alias: 'verificationDate',
        },
        {
          text: 'Дата следующей поверки',
          order: 'nextVerificationDate',
          alias: 'nextVerificationDate',
        },
      ];

      const editButtonField = {
        alias: 'actions',
      };

      return this.showEditButton
        ? [...baseFields, editButtonField]
        : baseFields;
    },

    modalFields() {
      return [
        {
          type: 'text',
          key: 'factoryNumber',
          label: 'Заводской номер',
        },
        {
          type: 'select',
          key: 'resourceId',
          label: 'Измеряемый ресурс',
          list: this.resources,
        },
        {
          type: 'integerNumber',
          key: 'recheckInterval',
          label: 'Межповерочный интервал',
          attributes: {
            options: {
              minimumValue: 0,
              decimalPlaces: 0,
            },
          },
        },
        {
          type: 'decimalNumber',
          key: 'value',
          label: 'Показание при поверке',
          attributes: {
            options: {
              minimumValue: 0,
              decimalPlaces: 4,
            },
          },
        },
        {
          type: 'date',
          key: 'verificationDate',
          label: 'Дата поверки',
          attributes: {
            disabledDate: this.verificationDateDisabled,
          },
        },
        {
          type: 'date',
          key: 'nextVerificationDate',
          label: 'Дата следующей поверки',
          attributes: {
            disabledDate: this.nextVerificationDateDisabled,
          },
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const meterInfo = this.meterInfo({
      bduId: this.$route.params.id,
    })
      .then((data) => {
        this.items = data;
      });

    const resources = this.getResources({ bduId: this.$route.params.id });

    Promise.all([meterInfo, resources])
      .finally(() => {
        this.items.forEach((item) => {
          this.$set(item, 'resourceName', this.resources?.find((resource) => resource.id === item.resourceId)?.name);
        });

        this.initialItems = JSON.parse(JSON.stringify(this.items));

        this.isLoadingPage = false;
      });
  },

  methods: {
    ...mapActions({
      meterInfo: 'meterRecheckRequests/meterInfo',
      getResources: 'meterRecheckRequests/resources',
      updateMetersInfo: 'meterRecheckRequests/updateMetersInfo',
    }),

    verificationDateDisabled(date) {
      return this.$moment(date, 'DD.MM.YYYY') > this.$moment(this.modalForm.nextVerificationDate, 'DD.MM.YYYY');
    },

    nextVerificationDateDisabled(date) {
      return this.$moment(date, 'DD.MM.YYYY') < this.$moment(this.modalForm.verificationDate, 'DD.MM.YYYY');
    },

    onAdd() {
      this.modalTitle = 'Создание ПУ';
      this.editItemIndex = null;

      this.modalForm = {
        factoryNumber: '',
        resourceId: 0,
        resourceName: '',
        recheckInterval: 0,
        value: 0,
        verificationDate: '',
        nextVerificationDate: '',
      };

      this.showModal = true;
    },

    onEdit(index) {
      this.modalTitle = 'Редактирование ПУ';
      this.editItemIndex = index;

      this.modalForm = { ...this.items[index] };

      this.showModal = true;
    },

    onDelete(index) {
      this.$root
        .$confirmModal({
          message: 'Удалить запись?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.items.splice(index, 1);
          }
        });
    },

    onCancelModal() {
      this.showModal = false;
    },

    onConfirmModal() {
      this.showModal = false;

      this.modalForm.resourceName = this.resources?.find((resource) => resource.id === this.modalForm.resourceId)?.name;

      if (this.editItemIndex !== null) {
        this.items.splice(this.editItemIndex, 1, {
          ...this.modalForm,
        });
      } else {
        this.items.push({ ...this.modalForm });
      }
    },

    onSave() {
      this.updateMetersInfo({
        bduId: parseInt(this.$route.params.id),
        MetersInfo: this.items,
      }).then(({ isSucceed }) => {
        if (isSucceed) {
          this.$notify({
            header: 'Успех!',
            text: 'Список поверенных приборов обновлен успешно',
            type: 'success',
            timer: 5000,
          });

          this.$router.push({ name: 'MeterRecheckRequests' });
        }
      });
    },

    onCancel() {
      this.$router.push({ name: 'MeterRecheckRequests' });
    },
  },
};
</script>

<style lang="scss">
.editable-list {
  &__breadcrumbs {
    margin-bottom: 15px;
  }

  &__top-panel-button {
    display: flex;
    align-items: center;
    margin: 0;
    border: none;
    background: transparent;
    font-weight: 500;
    line-height: 1;
    color: var(--gray);
  }

  &__top-panel-button--create:hover {
    color: var(--blue);
  }

  .filter__button {
    margin-right: 15px;
    width: 180px;
  }

  &__datatable-button {
    font-size: 0;
    color: var(--gray);
  }
}

.meter-recheck-requests__modal {
  .select {
    padding-right: 12px;
  }
}
</style>
